import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import styled from 'styled-components';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';

import addLocation from '../../redux/actions/addLocation';
import setMapPosition from '../../redux/actions/setMapPosition';
import setMapAddress from '../../redux/actions/setMapAddress';
import SubmitForm, {Title, Text, TextField} from '../common/Form';
import Button from '../common/Button';
import LoadingSpinner from '../common/LoadingSpinner';
import {ErrorAlert} from '../common/Alerts';
import LocationOwnerSelect from "../Location/LocationOwnerSelect";

class AddLocationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        name:        {value: 'Симферополь, АЗС 1', valid: undefined},
        owner_id:    {value: '', valid: undefined},
        address:     {value: '', valid: undefined},
        country:     {value: 'Россия', valid: undefined},
        city:        {value: 'Симферополь', valid: undefined},
        street:      {value: 'улица Гагарина, 1', valid: undefined},
        lat:         {value: '44.962582', valid: undefined},
        lng:         {value: '34.085226', valid: undefined},
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const data = {...this.state.data};

    if (nextProps.address) {
      const {country, city, street} = nextProps.address;

      if (street) {
        data.street.value      = street      || '';
      }

      if (city) {
        data.city.value = city || '';
      }

      if (country) {
        data.country.value   = country;
      }

      this.setState({data});
    }
  }

  setData = (name, value, valid) => {
    const data = {...this.state.data};

    data[name].value = value;
    data[name].valid = valid;

    this.setState({ data });
  };

  fieldIsValid = (name, value) => {
    return value.length !== 0;
  };

  handleChange = event => {
    const {name, value} = event.target;

    const valid = this.fieldIsValid(name, value);

    this.setData(name, value, valid);

    // const address = this.state.data.address.value;

    // if (address.length > 3) {
    //   geocode(this.props.authorization, {address})
    //     .then(addressObject => {
    //       if (addressObject && addressObject.location) {
    //         this.props.setMapPosition({
    //           lat: addressObject.location.lat,
    //           lng: addressObject.location.lng,
    //         });
    //         this.props.setMapAddress(addressObject);
    //       }
    //     });
    // }
  };

  handleSubmit = event => {
    event.preventDefault();
    const {data: {lat, lng, ...data}} = this.state;
    const formData = {};

    for (let name in data) {
      formData[name] = data[name].value;
    }

    formData.coordinates = {
      lat: lat.value,
      lng: lng.value,
    };

    const {addLocation, authorization, history} = this.props;

    if (authorization) {
      addLocation({ authorization }, formData, () => history.push('/locations'));
    }
  };

  handleCoordinatesSetToMap = () => {
    const { data: { lat, lng } } = this.state;

    this.props.setMapPosition({
      lat: lat.value,
      lng: lng.value,
    });
  }

  handleCoordinatesGetFromMap = () => {
    const data = {...this.state.data};
    const props = this.props;

    data.lat.value = props.coordinates?.lat;
    data.lng.value = props.coordinates?.lng;

    this.setState({ data });
  }

  validation() {
    if (!this.state.data?.name?.value) {
      return false;
    }

    return true;
  }

  render() {
    const {handleChange, handleSubmit,
      handleCoordinatesGetFromMap, handleCoordinatesSetToMap,
      props} = this;
    const {loading, error, t} = props;

    const {
      name,
      country,
      city,
      street,
      lat,
      lng,
      owner_id,
    } = this.state.data;

    const allFieldsValid = this.validation();

    if (loading) {
      return (
        <Container>
          <LoadingSpinner />
        </Container>
      );
    }

    return (
      <Container>
        <Form onSubmit={handleSubmit} width="50%">
          <Title textAlign="left">{t('addChargingLocation')}</Title>
          <Text textAlign="left">
            {t('locationAddInfo')}
          </Text>
          <TextField
            name="name" value={name.value} valid={name.valid}
            label={t('locationName')}
            handleChange={handleChange} width="100%"
          />
          <TextField
            name="country" value={country.value} valid={country.valid}
            label="Страна"
            margin="20px 0 0 0"
            handleChange={handleChange} width="100%"
          />
          <TextField
            name="city" value={city.value} valid={city.valid}
            label="Город"
            handleChange={handleChange} width="100%"
          />
          <TextField
            name="street" value={street.value} valid={street.valid}
            label="Улица"
            handleChange={handleChange} width="100%"
          />

          <TextField
            name="lat" value={lat.value} valid={lat.valid}
            label="Широта"
            margin="20px 5px 0 0"
            handleChange={handleChange} width="calc(50% - 5px)"
          />
          <TextField
            name="lng" value={lng.value} valid={lng.valid}
            label="Долгота"
            margin="20px 0 0 5px"
            handleChange={handleChange} width="calc(50% - 5px)"
          />
          <Button
            theme="white" margin="0 5px 0 0" width="calc(50% - 5px)" float="left"
            onClick={handleCoordinatesSetToMap}
          >
            Показать на карте
          </Button>
          <Button
            theme="white" margin="0 0 0 5px" width="calc(50% - 5px)" float="right"
            onClick={handleCoordinatesGetFromMap}
          >
            Взять с карты
          </Button>

          <LocationOwnerSelect
            value={owner_id.value}
            onChange={handleChange}
            style={{ marginTop: 20, float: "left" }}
          />

          <ButtonContainer>
            {error && error.message && <ErrorAlert>{error.message}</ErrorAlert>}
            <Button type="submit" disabled={!allFieldsValid}
              theme="blue" width="100%" margin="20px 6px 0 0" float="left">
              {t('addLocation')}
            </Button>
          </ButtonContainer>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  @media screen and (max-width: 992px) {
    display: block;
    min-width: 50%; max-width: 50%;
    padding: 6.8vw 0;
  }
  @media screen and (min-width: 992px) {
    display: inline-flex;
    align-items: stretch;
    min-width: 50%; max-width: 50%;
    padding: 6.8vw 0;
    margin: auto;
  }
`;

const Form = styled(SubmitForm)`
  position: relative;
  height: auto;
  @media screen and (max-width: 992px) {
    min-width: 90%; max-width: 90%;
  }
  @media screen and (min-width: 992px) {
    min-width: 50%; max-width: 50%;
  }
`;

const ButtonContainer = styled.div`
  display: block;
  text-align: center;
`;

const mapStateToProps = ({manager, map, locations}) => ({
  authorization: manager.accessToken && `Bearer ${manager.accessToken}`,
  coordinates: map.position,
  address: map.address,
  ...locations,
});

const mapDispatchToProps = dispatch => ({
  addLocation: (authorization, data, callbcak) =>
    dispatch(addLocation(authorization, data, callbcak)),
  setMapPosition: position => dispatch(setMapPosition(position)),
  setMapAddress: position => dispatch(setMapAddress(position)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withRouter
)(AddLocationForm);
