import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import * as colors from '../../util/colors';
import bp, {getWindowWidth} from '../common/breakpoints';
import Topbar from '../common/Topbar';

const AppManual = ({match}) => {
    const { t } = useTranslation();

    return (
        <Container style={{margin: '16px 0'}}>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                Инструкция использования приложения
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>Во вкладке  «Профиль» выберите «Методы оплат» и добавьте банковскую карту для списания. Эту карту можно будет указать, как метод оплаты или пополнить «Личный счёт» в приложении, указав «Личный счёт» как метод оплаты.
                        При добавлении карты банка РНКБ советуем использовать личный счет в качестве метода оплаты, а пополнять его через добавленную карту РНКБ (т.к. по картам РНКБ наблюдаются задержки при разблокировке остатка). </li>
                    <li>На вкладке «Карта» в нижнем правом углу активируйте  геопозицию для привязки вашего местонахождения на карте. В верхнем правом углу нажмите на значок «Фильтр» и настройте отображение на карте согласно вашим пожеланиям. </li>
                    <li>На вкладке «Список» можно увидеть все доступные станции. </li>
                    <li>Для использования станции, необходимо выбрать ее на вкладке «Карта» или на вкладке «Список», далее выбрать нужный Вам коннектор и задать параметры зарядки, после чего подтвердить свой выбор и следовать по подсказкам на экране.</li>
                    <li>После окончания зарядки отключите коннектор и освободите станцию. По истечению 10 минут будет включена плата за простой авто с подключенным коннектором - 150 р. за каждые последующие 10 минут.</li>
                    <li>Историю Ваших зарядок можно просмотреть во вкладке «Профиль/История зарядок»</li>
                </ul>
            </Text>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                Оплата
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>Методы оплаты:<br />
                        - Банковскими картами.<br />
                        - С личного счета.<br />
                        - Наличными (доступно на некоторых АЗС АТАН и ТЭС через оператора заправочной станции).<br />
                    </li>
                    <li>Порядок оплаты с банковских карт:<br />
                        - Привяжите карту/карты оплаты.<br />
                        - При создании заказа выберите требуемую карту в методе оплаты.<br />
                        - Оплата производится за фактические заряженные киловатт-часы.<br />
                        - При начале зарядке мы блокируем(замораживаем) на карте предположительную стоимость зарядки, но не списываем! По окончанию зарядки производим расчет - списываем только за количество фактически заряженных кВтч, остальная сумма автоматически разблокируется/возвращается.
                        <br />
                        - Некоторые банки не всегда корректно отправляют смс о блокировке/разблокировке, вводя клиентов в заблуждение. Лучше заходить интернет-банк в историю платежей и смотреть там.
                        Также, в зависимости от банка, бывают одиночные случаи что сумма 'размораживается' не сразу, но максимум в течении 3-х рабочих дней данные корректно обновляются.<br />
                    </li>

                    <li>Порядок оплаты с личного счета:<br />
                        - Пополните личный счет через СБП.<br />
                        - Либо пополните ранее привязанной картой.<br />
                        - При создании заказа выберите личный счет в методе оплаты.<br />
                    </li>

                    <li>Порядок оплаты наличными:<br />
                        - Уточните у оператора АЗС возможность оплаты наличными за услугу зарядки.<br />
                        - Следуйте инструкциям оператора.<br />
                    </li>
                </ul>
            </Text>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                Бронирование (резервирование)
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>Вы можете забронировать на нужное время требуемый разъем станции.</li>
                    <li>Для бронирования перейдите на экран «Резервирование», укажите максимальную стоимость зарядки(неизрасходованный остаток по окончанию зарядной сессии вернется), выберите доступный для бронирования интервал и укажите желаемое время начала бронирования.</li>
                    <li>Максимальная длительность бронирования - 1 час (либо длительность выбранного интервала, если он меньше 1 часа)</li>
                    <li>Стоимость бронирования - 100 р.</li>
                    <li>На вкладке «Резервирования» можно увидеть все бронирования. </li>
                    <li>Как подойдет время бронирования у вас будет 10 минут для подключения к станции. По истечению 10 минут бронирование будет отменено (без возврата стоимости бронирования). </li>
                    <li>Чтобы начать зарядку нужно зайти в требуемый коннектор как подойдет время бронирования. Либо перейти по нажатию на появившийся на вкладке списка станций либо карты «баннер»(вверху экрана).</li>
                </ul>
            </Text>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                Порядок добавления умной метки *
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>Подключаем авто к зарядной станции.</li>
                    <li>Запускаем зарядку в приложении(не позднее чем 1.5 минуты после подключения авто)</li>
                    <li>Чип добавится в приложение автоматически, но будет неактивным. Нужно перейти в раздел "Умная зарядка(мои чипы)" и включить его.</li>
                    <li>Если в момент добавления на станции идет параллельная зарядка - чип может не добавиться.</li>
                    <li>* На данный момент работает только для автомобилей Tesla и оборудованных чипом умной зарядки.</li>
                </ul>
            </Text>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                 Кэшбек
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>На некоторых зарядных станциях начисляется кэшбэк. Такие станции помечены соответствующей подписью.</li>
                    <li>Раз в месяц кэшбэк зачисляется на Ваш личный счет.</li>
                    <li>Текущий размер кэшбэка отображается в методах оплат.</li>
                </ul>
            </Text>
            <Title size={'18px'} weight='bold' style={{margin: '20px 0 10px 23px'}}>
                Частые вопросы:
            </Title>
            <Text style={{fontFamily: 'Roboto Regular', fontSize: '16px', margin: '0 16px 16px 0'}}>

                <ul>
                    <li>Не вернулась "сдача" после зарядки, что делать?<br />
                        - В отдельных случаях(сбой станции/проблемы со связью) разблокировка остатка может задержаться.<br />
                        - Если в течении 3-х дней остаток так и не вернулся, запросите в вашем банке выписку по проблемному платежу с указанием статуса платежа. Затем свяжитесь с нами.<br />
                    </li>
                </ul>
            </Text>

            <Text style={{fontFamily: 'Roboto Regular', fontSize: '14px', margin: '0 16px 16px 0'}}>
                <ul>
                    <li>Телефон поддержки: 8 800 100-17-58</li>
                </ul>
            </Text>

        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%; min-width: 100%;
  @media screen and (max-width: ${bp.phone}) {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    margin-top: 56px;
  }
  @media screen and (min-width: ${bp.phone}) {
    padding-top: calc(56px + 10vh);
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-top: 32px;
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

const Text = styled.div`
  font-size: ${props => props.size || '20px'};
  font-weight: ${props => props.weight || 'normal'};
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: ${bp.phone}) {
    margin-left: 16px;
  }
`;


export default AppManual;
